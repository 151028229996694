export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const isLight = (rgb) => {
    const Y = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;

    if ((rgb.r + rgb.g + rgb.b) >= (255 * 3 / 2))
        return true; // color is light

    return false; // color is dark
}

