import { Link, useLocation } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import Wysiwyg from '../Wysiwyg';

import { node, object, string } from 'prop-types';

import styles from './index.module.scss';

const TileCard = ({ title, subtext, imageUrl, url, sx = {}, bottomAction }) => {
    const location = useLocation();

    const linkTo = {
        pathname: url,
        state: {
            from: `${location.pathname}${location.search}`
        }
    };

    return (
        <>
            <Box
                className={styles.tile}
                sx={{
                    position: 'relative',
                    color: '#ffffff',
                    border: '1px solid #000',
                    background: `linear-gradient(to bottom, RGBA(0, 0, 0, 0.55), RGBA(0, 0, 0, 0.15) 57%, RGBA(0, 0, 0, 0.3)), url(${imageUrl}) no-repeat center center`,
                    backgroundSize: "contain",
                    position: 'relative',
                    ...sx
                }}
            >
                <Link
                    to={linkTo}
                    style={{ textDecoration: 'none' }}
                    className="w-100"
                >
                    <Box
                        direction="column"
                        justifyContent="flex-end"
                        className={styles.tile}
                    >
                        <Grid
                            container
                            spacing={1}
                            justifyContent="flex-end"
                            sx={{
                                pr: 1,
                                ml: 'auto',
                                width: 'fit-content'
                            }}
                        >
                            <Grid item xs={12}>
                                <Box>
                                    {bottomAction}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Link>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9,
                        overflow: 'hidden',
                        my: {
                            xs: 2,
                            md: 3,
                            lg: 3,
                        },
                        mx: {
                            xs: 2,
                            md: 3,
                            lg: 3,
                        },
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            my: 0,
                            color: '#ffffff',
                            fontSize: {
                                xs: '1.5rem',
                                md: '2rem',
                            },
                            fontWeight: 'bold'
                        }}
                    >
                        <Wysiwyg>
                            {title}
                        </Wysiwyg>
                    </Typography>

                    <Link
                        to={linkTo}
                        style={{ textDecoration: 'none' }}
                        className="w-100"
                    >
                        <Typography
                            component="div"
                            sx={{
                                my: 0,
                                color: '#ffffff',
                            }}
                        >
                            <Wysiwyg>
                                {subtext}
                            </Wysiwyg>
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </>
    )
};

TileCard.propTypes = {
    bottomAction: node,
    imageUrl: string.isRequired,
    title: string.isRequired,
    subtext: string,
    sx: object,
    url: string.isRequired,
}

export default TileCard;