import { combineReducers } from 'redux';

import filtersReducer from './filters';
import searchReducer from './search';
import languageReducer from './language';
import provinceReducer from './province';
import headerReducer from './header';

const rootReducer = (state = {}, action) => {
    return {
        filters: filtersReducer(state.filters, action),
        header: headerReducer(state.header, action),
        search: searchReducer(state.query, action),
        language: languageReducer(state.language, action),
        province: provinceReducer(state.province, action),
    }
};

export default rootReducer;