import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { shape, string } from 'prop-types';
import Wysiwyg from '../Wysiwyg';

import styles from './index.module.scss';

const Gallery = ({title, content, imageBlocks, variant='default'}) => {

  let imagesContent = null;

  const defaultLayout = (
    <Box
      spacing={2}
      sx={{
        my: 3
      }}
    >
      {
        imageBlocks?.map(imageBlock => (
          <Grid
            key={imageBlock.id}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Box
                sx={{
                  py: 3,
                  px: 2
                }}
              >
                <img
                  className={styles.image}
                  src={imageBlock.imageUrl}
                  alt={imageBlock.title}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={8}
            >
              <Box>
                <Typography variant={'h4'}>
                  <strong>
                    {imageBlock.title}
                  </strong>
                </Typography>
                <Typography>
                  <Wysiwyg>
                    {imageBlock.content}
                  </Wysiwyg>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))
      }
    </Box>
  );

  const gridLayoutVariant = (
    <Box
      spacing={2}
      sx={{
        my: 3
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        {
          imageBlocks?.map(imageBlock => (
            <Grid
              item
              key={imageBlock.id}
              alignItems="center"
              justifyContent="center"
              xs={12}
              md={6}
              sx={{}}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%',
                  width: '100%',
                  border: '1px solid #cacbcd',
                }}
              >
                <img
                  className={styles.image}
                  src={imageBlock.imageUrl}
                  alt={imageBlock.title}
                />
              </Stack>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )

  switch (variant) {
    case 'default':
      imagesContent = defaultLayout;
      break;
    case 'grid':
      imagesContent = gridLayoutVariant;
      break;
    default:
      imagesContent = defaultLayout;
      break;
  }

  return (
    <Box>
      <Typography
        variant='h2'
      >
        <Wysiwyg>
          {title}
        </Wysiwyg>
      </Typography>

      <Typography
        variant='div'
      >
        <Wysiwyg>
          {content}
        </Wysiwyg>
      </Typography>

      {imagesContent}
    </Box>
  );
};

Gallery.propTypes = {
  title: string,
  content: string,
  variant: string,
  imageBlocks: shape({
    imageUrl: string.isRequired,
    caption: string,
    title: string,
    content: string,
  })
};

export default Gallery;
