import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Grid, Link, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import linkifyStr from "linkify-string";

import api from '../../api';

import notify from '../../components/Notification/helper';
import PageHeader from '../../components/PageHeader';

import styles from './index.module.scss';
import Wysiwyg from '../../components/Wysiwyg';
import AccordionSection from '../../components/AccordionSection';
import { t } from 'i18next';
import Recipes from '../../components/Collection/Recipes';
import Collections from '../../components/Collections';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import constants from '../../utils/constants';
import Videos from '../../components/Videos';
import Loader from '../../components/Loader';
import { useRetailerSettings } from '../../hooks/useRetailerSettings';
import ListItem from '../../components/ListItem';
import { getTranslation } from '../../utils/translate';
import { useSelector } from 'react-redux';
import { useUpdateDocumentMeta } from '../../hooks/useUpdateDocumentMeta';
import { getAuthorDisplayName } from '../../utils/author';

function Author() {
  const { code: languageCode } = useSelector(state => state.language);

  const [author, setAuthor] = useState({});
  const [loading, setLoading] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [collections, setCollections] = useState([]);
  const [socialMediaPages, setSocialMediaPages] = useState([]);
  const [videos, setVideos] = useState([]);

  const authorId = useParams().authorId;

  const {retailerSettings} = useRetailerSettings();

  const socialMediaConfigs = {
    insta: {
      icon: <Instagram
        sx={{
          fontSize: '24px'
        }}
      />,
      url: 'https://instagram.com/',
    },
    fb: {
      icon: <Facebook
      sx={{
          fontSize: '26px'
        }}
      />,
      url: 'https://facebook.com/',
    },
    twitter: {
      icon: <Twitter
      sx={{
          fontSize: '26px'
        }}
      />,
      url: 'https://twitter.com/',
    },
    youtube: {
      icon: <YouTube
      sx={{
          fontSize: '32px'
        }}
      />,
      url: 'https://youtube.com/',
    },
  };

  const apiFieldsToFetch = [
    '*',
    'collections.collections_id.*',
    'collections.collections_id.translations.*',
    'pages.pages_id.*',
    'pages.pages_id.retailers.*',
    'pages.pages_id.translations.*',
    'recipes.recipe_id.*',
    'recipes.recipe_id.layout_translations.*',
    'recipes.recipe_id.imagegallery.*',
    'translations.*',
  ];

  useUpdateDocumentMeta(getAuthorDisplayName(author));

  useEffect(async() => {
    try {
      setLoading(true);

      const getAuthorResponse = await api.getAuthor(authorId, apiFieldsToFetch);

      // build image URL
      const imageUrl = getAuthorResponse.image ?
        `${process.env.REACT_APP_API_URL}/assets/${getAuthorResponse.image}?key=page-hero-image-contain` :
        null;
      const display_name = getAuthorResponse.display_name || `${getAuthorResponse.first_name} ${getAuthorResponse.last_name}`;
      const credentials = `${getAuthorResponse.credentials || ''} <strong>${getAuthorResponse.organization || ''}</strong>`;

      // build videos data
      setVideos([
        ...(
          getAuthorResponse.vimeo?.map(vimeo => {
            const id = vimeo.vimeo_id.replace('https://vimeo.com/', '');

            return {
              id,
              sourceUrl: `${constants.VIMEO_PLAYER_URL}/${id}`,
              videoType: 'vimeo',
            }
          }) || []
        ),
        ...(
          getAuthorResponse.youtube?.map(youtube => ({
            id: youtube.youtube_id,
            sourceUrl: `${constants.YOUTUBE_PLAYER_URL}/${youtube.youtube_id}`,
            videoType: 'youtube',
          })) || []
        )
      ]);

      const translatedAuthor = getTranslation(getAuthorResponse, { key: 'languages_code', code: languageCode});
      
      setAuthor({
        ...getAuthorResponse,
        biography: linkifyStr(translatedAuthor?.biography || '', { className: styles.link_underline }),
        display_name,
        credentials,
        imageUrl
      });

      setSocialMediaPages(
        getAuthorResponse.social
          ?.map(social => {
            const socialMedia = socialMediaConfigs[social.channel];
            return {
              icon: socialMedia.icon,
              url: (socialMedia.url || '') + social.user.replace('@', '')
            }
          }
        ) || []
      )

      setRecipes(getAuthorResponse.recipes
        ?.filter(junction => junction.recipe_id.status === 'published')
        ?.map(junction => junction.recipe_id)
      );

      setCollections(getAuthorResponse.collections
        ?.filter(junction => junction.collections_id?.type !== 'CUISINE')
        ?.map(junction => junction.collections_id)
      );

      setCuisines(getAuthorResponse.collections
        ?.filter(junction => junction.collections_id?.type === 'CUISINE')
        ?.map(junction => junction.collections_id)
      );
    } catch(error) {
        error?.map((err) => notify('error', err.message));
    } finally {
        setLoading(false);
    }
  }, [authorId, languageCode]);

  if (loading) {
    return <Loader />
  }

  return (
    <Box
      className={'author'}
    >
      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{ 
              name: author.display_name,
            }}
            useAsLink={false}
          />
        </Box>
      </Container>

      <Container
        sx={{
          my: 3,
        }}
      >
        {
          !!author.imageUrl &&
          <Grid
            className='collection_img_container'
            item
            sm={12}
            sx={{
              textAlign: 'center'
            }}
          >
            <img
              className={styles.author_image}
              src={author.imageUrl}
              alt={author.display_name}
            />
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              spacing={1}
              sx={{
                my: 1
              }}
            >
              {
                socialMediaPages.map(socialMediaPage => (
                  <Link
                    key={socialMediaPage.url}
                    href={socialMediaPage.url}
                    target='_blank'
                    aria-label="social-media-link"
                    sx={{
                      color: '#36454F',
                    }}
                  >
                    {socialMediaPage.icon}
                  </Link>
                ))
              }
            </Stack>
            {/* <i>
              <Wysiwyg>
                {author.credentials}
              </Wysiwyg>
            </i> */}
          </Grid>
        }

        <Typography
          sx={{
            textAlign: 'justify'
          }}
          component="div"
        >
            <Wysiwyg>
              {author.biography?.replace(/\n/g, '<br />')}
            </Wysiwyg>
        </Typography>

        {
          !!cuisines?.length &&
          <AccordionSection
            title={t('cuisine')}
            expanded={true}
            sx={{
              mt: 3
            }}
          >
            <Collections
              collections={cuisines}
            />
          </AccordionSection>
        }

        {
          <AccordionSection
            title={t('articles')}
            expanded={true}
            sx={{
              mt: 3
            }}
          >
            <Grid container>
                {
                  author?.pages
                  ?.filter(({ pages_id:article }) => {
                    const retailerIds = article?.retailers?.map(retailer => retailer.retailer_id)
                    return retailerIds?.includes(retailerSettings.id)
                  })
                  ?.map(({ pages_id:article }) => (
                    <Grid
                      item
                      key={article.id}
                      sx={{}}
                      sm={12}
                      md={6}
                    >
                      <ListItem
                        url={`/article/${article.id}`}
                        variant='column'
                        image={`${process.env.REACT_APP_API_URL}/assets/${article.image}?key=page-hero-image-contain`}
                        subtitle={getTranslation(article.category, { key: 'languages_code', code: languageCode}).name}
                        title={getTranslation(article, { key: 'languages_code', code: languageCode}).title}
                        description={getTranslation(article, { key: 'languages_code', code: languageCode}).summary}
                      />
                    </Grid>
                  ))
                }
            </Grid>
          </AccordionSection>
        }

        {
          !!videos?.length &&
          <AccordionSection
            title={t('videos')}
            expanded={true}
            sx={{
              mt: 3
            }}
          >
            <Videos
              videos={videos}
            />
          </AccordionSection>
        }

        {
          !!collections?.length &&
          <AccordionSection
            title={t('collections')}
            expanded={true}
            sx={{
              mt: 3
            }}
          >
            <Collections
              collections={collections}
            />
          </AccordionSection>
        }

        {
          !!recipes?.length &&
          <AccordionSection
            title={t('recipes')}
            expanded={true}
            sx={{
              mt: 3
            }}
          >
            <Recipes
              recipes={recipes}
            />
          </AccordionSection>
        }
      </Container>
    </Box>
  )
}

export default Author