import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

import RetailerContext from '../../context';

import BackgroundImage from '../../shared/img/background-book.png';

import { getTranslation } from '../../utils/translate';

import Wysiwyg from '../Wysiwyg';

import './index.scss';

const BeefProductBrand = ({ item, to }) => {
  const retailerContext = useContext(RetailerContext);
  const language = useSelector(state => state.language);

  const translatedItem = getTranslation(item, { key: 'languages_code', code: language.code });

  const backgroundImage = item.image ?
    `${process.env.REACT_APP_API_URL}/assets/${item.image}?quality=25` :
    BackgroundImage;

  return (
    <Link to={to || `/beef-product-brand/${item.id}`}>
      <Box
        className='dashboard_card'
        sx={{
          position: 'relative',
          mb: 2,
        }}
      >
        <Card
          sx={{
            backgroundColor: 'transparent',
            borderRadius: 0
          }}>
          <CardContent
            sx={{
              p: 0,
              background: item.brand_color || retailerContext.retailer.brand_color_2
            }}
          >
            <Stack
              sx={{
                px: '16px',
                py: '8px',
                mr: '60px'
              }}
              direction="row"
              alignItems="center"
            >
              <Typography sx={{ fontSize: '20px', lineHeight: 1, fontWeight: 'bold', color: '#fff' }} variant='h1' className="text-primary">
                {item.name || translatedItem.name}
              </Typography>
            </Stack>
          </CardContent>
          <CardContent
            sx={{
              backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%), url(${backgroundImage}) !important`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundBlendMode: "lighten",
              backgroundSize: "cover",
            }}
          >
            <Stack sx={{ mt: 2 }}>
              {
                translatedItem?.description?.blocks?.length ?
                  translatedItem?.description?.blocks.map((block) => (
                    <Stack
                      key={block.id}
                      sx={{ mb: 1 }}
                    >
                      {block.type === 'paragraph' &&
                        <Typography
                          sx={{
                            textAlign: 'justify',
                            color: '#302B2B',
                          }}
                        >
                          <Wysiwyg>
                            {block.data.text}
                          </Wysiwyg>
                        </Typography>
                      }

                      {block.type === 'header' &&
                        <Typography sx={{ mb: 1 }} variant={`h${block.data.level}`}>
                          <Wysiwyg>
                            {block.data.text}
                          </Wysiwyg>
                        </Typography>
                      }
                    </Stack>
                  )) : (
                    <Typography sx={{}}>
                      <Wysiwyg>
                        {item?.description}
                      </Wysiwyg>
                    </Typography>
                  )
              }
            </Stack>
          </CardContent>
        </Card>
        {
          item.logo &&
          <Box
            className='dashboard_stripes'
            sx={{
              position: 'absolute',
              top: 8,
              right: 16,
              height: '48px',
              width: '48px'
            }}
          >
            <Box sx={{ width: '100%', height: '100%' }}>
              <img
                className="list_img w-100"
                src={`${process.env.REACT_APP_API_URL}/assets/${item.logo}`}
                alt={translatedItem.name}
              />
            </Box>
          </Box>
        }
      </Box>
    </Link>
  );
};

BeefProductBrand.propTypes = {
  item: PropTypes.object.isRequired,
};

export default BeefProductBrand;
