import React from 'react';
import Notification from './components/Notification';
import AppRouter from './routers/AppRouter';

import './styles/App.scss';
import './styles/index.scss';

const App = () => (
  <>
    <Notification />
    <AppRouter />
  </>
);

export default App;
