import { useEffect, useState } from 'react';

import { 
    Box,
    Container,
    Stack,
    Typography,
} from '@mui/material';

import Steak from '../../shared/svg/Steak.svg';

import './index.scss';
import Wysiwyg from '../Wysiwyg';
import { string } from 'prop-types';

const PageHeader = ({ pageTitle='&nbsp;', item = {} }) => {
    const [icon, setIcon] = useState(null);

    useEffect(() => {
        setIcon((item.svg_icon ? `${process.env.REACT_APP_API_URL}/assets/${item.svg_icon}?key=title-icon` : null));
    }, [item]);
    

    return (
        <Box
            className='page-header'
            sx={{
                px: 0,
                boxShadow: '0 16px 15px 0 rgba(0, 0, 0, 0.18)',
                py: 0,
            }}
        >
            <Container
                className='padding-on-mobile'
            >                
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{
                        pt: 4,
                        pb: 2,
                    }}
                >
                    <Typography
                        className={'title'}
                        variant="h1"
                        sx={{
                            fontSize: '2rem',
                            fontWeight: 'bolder',
                            textTransform: 'uppercase',
                        }}
                    >
                        <Wysiwyg>
                            { item.name || pageTitle }
                        </Wysiwyg>
                    </Typography>

                    {
                        !!icon && (
                            <Box
                                sx={{
                                    ml: 1
                                }}
                            >
                                <img
                                    className="icon"
                                    src={icon}
                                    alt={item.name}
                                    onError={() => {setIcon(Steak)}}
                                />
                            </Box>
                        )
                    }
                </Stack>
            </Container>
        </Box>
    )
};

PageHeader.propTypes = {
    pageTitle: string,
}

export default PageHeader;