import axios from "axios";
import { lang } from "moment/moment";

const baseUrl = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_ACCESS_TOKEN;

const geolocateUsingIp = async (lat, lng) => {
	try {
		const res = await axios.get(`https://ipapi.co/json`, {
			params: {},
		});

		return res.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const reverseGeocode = async (lat, lng) => {
	try {
		const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
			params: {
				key: 'AIzaSyAboOGBdAinRq8e7rHu_WmOFX66i6BU2vc',
				latlng: `${lat},${lng}`,
				result_type: 'administrative_area_level_1'
			},
		});

		return res.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListNutrientLabels = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/nutrition/`, {
			params: {
				...{ 
					access_token: token,
					fields: '*,translations.*',
					limit: 20
				},
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListCoupons = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/coupon/`, {
			params: {
				...{ 
					access_token: token,
					fields: '*,translations.*'
				},
				...filterParams
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListProducts = async (
		filterParams = {},
		fields = 'id,parent_group,beef_product_overrides.translations.*,beef_product_brandings.beef_product_branding_id.translations.*,beef_product_brandings.beef_product_branding_id.retailers.*,beef_product_brandings.beef_product_branding_id.image,beef_product_brandings.beef_product_branding_id.logo,beef_product_brandings.beef_product_branding_id.brand_color,beef_product_brandings.beef_product_branding_id.id,retailers.*,owner,cooking_methods.Recommended,cooking_methods.translations.*,cooking_methods.id,cooking_methods.cooking_method.*,cooking_methods.cooking_method.translations.*,translations.*,svg_icon,image,retailer,status,date_created,recipes.recipe_id.retailer,recipes.recipe_id.retailers.retailer_id,Nutrients.*.*'
	) => {
		const params = {
			access_token: token,
			fields
		};

		try {
			const res = await axios.get(`${baseUrl}/items/beef_product/`, {
				params: {...params, ...filterParams}
			});
			return res.data.data;
		} catch (error) {
			throw handleResponseError(error);
		}
};

const getCookingMethods = async (filterParams = {}) => {
	const params = {
		access_token: token,
		fields: "id,image,retailers.sort,retailers.retailer_id,translations.*",
	};

	try {
		const res = await axios.get(`${baseUrl}/items/cooking_method/`, {
			params: {...params, ...filterParams}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListCategory = async (filterParams = {}) => {
	const params = {
		access_token: token,
		fields: "id,category,status,recipe,recipe.recipe_id.retailer,recipe.recipe_id.retailers.retailer_id,image,translations.*",
	};

	try {
		const res = await axios.get(`${baseUrl}/items/recipe_categories/`, {
			params: {...params, ...filterParams}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListRecipe = async (productId = null, cookingMethodId = null, search = null, filterParams = {}, categoryID = null) => {
	const params = {
		access_token: token,
		sort: '-date_created',
		fields:
			"id,total_time,ingredients_list,layout_translations.*,imagegallery.*,portions,difficulty,yields,cooking_method.cooking_method_id.translations.*",
			
	};

	if (productId) {
		const filter = {
			beef_products: { beef_product_id: { _eq: productId } },
		};
		filterParams.filter._and.push(filter);
	}

	if (cookingMethodId) {
		const filter = {
			cooking_method: { cooking_method_id: { _eq: cookingMethodId } },
		};
		filterParams.filter._and.push(filter);
	}

	if (categoryID) {
		const filter = {
			category: { recipe_categories_id: { _eq: categoryID } },
		};
		filterParams.filter._and.push(filter);
	}

	try {
		const res = await axios.get(`${baseUrl}/items/recipe/`, { 
			params: {...params, ...filterParams}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const searchArticles = async (filterParams={}, fields=['*']) => {
	const params = {
		access_token: token,
		fields
	};

	try {
		const res = await axios.get(`${baseUrl}/items/pages/`, { 
			params: {
				...params, 
				...filterParams
			}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const searchRecipes = async (filterParams={}, fields=['*']) => {
	const params = {
		access_token: token,
		fields
	};

	try {
		const res = await axios.get(`${baseUrl}/items/recipe/`, { 
			params: {
				...params, 
				...filterParams
			}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const searchBeefCuts = async (filterParams={}, fields=['*']) => {
	const params = {
		access_token: token,
		fields
	};

	try {
		const res = await axios.get(`${baseUrl}/items/beef_product/`, { 
			params: {
				...params, 
				...filterParams
			}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const searchRecipeAuthors = async (filterParams={}, fields=['*']) => {
	const params = {
		access_token: token,
		fields
	};

	try {
		const res = await axios.get(`${baseUrl}/items/recipe_author/`, { 
			params: {
				...params, 
				...filterParams
			}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const searchCollections = async (filterParams={}, fields=['*']) => {
	const params = {
		access_token: token,
		fields
	};

	try {
		const res = await axios.get(`${baseUrl}/items/collections/`, { 
			params: {
				...params, 
				...filterParams
			}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const searchTags = async (searchString, fields) => {
	const params = {
		access_token: token,
		fields,
		filter: {
			'_and': ([
				{
					translations: {
						name: {
							'_contains': searchString
						}
					}
				},
				{
					status: {
						'_eq': 'published'
					}
				}
			])
		},
	};


	try {
		const res = await axios.get(`${baseUrl}/items/tags/`, { 
			params: {...params}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getLanguages = async () => {
	try {
		const res = await axios.get(`${baseUrl}/items/languages/`, {
			params: {
				access_token: token
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getPromotedProducts = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/ingredient/`, {
			params: {
				...{ 
					access_token: token,
					fields: '*,translations.*'
				},
				...filterParams
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getGenericRetailer = async () => {
	try {
		const res = await axios.get(`${baseUrl}/items/retailer/${process.env.REACT_APP_GENERIC_ID || 'b4405e7d-44f9-42b7-b13b-75e8743aa064'}`, {
			params: {
				access_token: token,
				fields: '*,domains.domain,domains.id,logo.*,translations.*'
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListMenus = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/menus/`, {
			params: {
				...{ access_token: token,
					fields: '*,translations.*'
				},
				...filterParams
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListProvinces = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/regions/`, {
			params: {
				...{ access_token: token,
					fields: '*,translations.*'
				},
				...filterParams
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListRedirectSites = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/redirect_sites/`, {
			params: {
				...{ access_token: token,
					fields: '*,translations.*'
				},
				...filterParams
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getListRetailer = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/retailer/`, {
			params: {
				...{ access_token: token,
					fields: '*,logo.*,translations.*'
				},
				...filterParams
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getBeefProductBrand = async (id, params = {}) => {
	try {
		const response = await axios.get(`${baseUrl}/items/beef_product_branding/${id}`, {
			params: {
				access_token: token,
				fields: 'id,image,translations.*,translations.videos.directus_files_id.*',
				...params
			}
		});

		return response.data.data;
	} catch(error) {
		throw handleResponseError(error);
	}
};

const getCookingMethod = async (id) => {
	try {
		const res = await axios.get(`${baseUrl}/items/cooking_method/${id}`, {
			params: {
				access_token: token,
				fields:
					"*,translations.*",
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getBeefProduct = async (id, fields) => {
	try {
		const res = await axios.get(`${baseUrl}/items/beef_product/${id}`, {
			params: {
				access_token: token,
				fields
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getAuthor = async (id, fields = '*') => {
	try {
		const res = await axios.get(`${baseUrl}/items/recipe_author/${id}`, {
			params: {
				access_token: token,
				fields
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getCollection = async (id, fields = '*,items.*.*.*') => {
	try {
		const res = await axios.get(`${baseUrl}/items/collections/${id}`, {
			params: {
				access_token: token,
				fields,
				sort: 'sort',
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getMenu = async (id, fields = '*,items.*.*.*') => {
	try {
		const res = await axios.get(`${baseUrl}/items/menus/${id}`, {
			params: {
				access_token: token,
				fields
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getDefaults = async (fields = '*') => {
	try {
		const res = await axios.get(`${baseUrl}/items/defaults`, {
			params: {
				access_token: token,
				fields
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getRecipe = async (id, filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/recipe/${id}`, {
			params: {
				...filterParams,
				access_token: token,
				fields:
					"*,layout_translations.*,coupons.coupon_id.*,coupons.coupon_id.translations.*,beef_products.*,imagegallery.*,cooking_method.*,category.recipe_categories_id.translations.*,category.recipe_categories_id.*,recipe_directions.*.*,youtube.*,video_overrides.status,video_overrides.retailer,video_overrides.translations.*",
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getBarcode = async (barcode, retailerId, unsupportedFilters) => {
	// if undefined, set to default filters for unsupported barcodes
	let unsupportedFiltersPayload = unsupportedFilters ? unsupportedFilters : [
		{
			unsupported_product: false
		},
		{
			not_canadian: false
		},
	];

	try {
		const filter = {
			_and: [
				{
					code: { _starts_with: barcode }
				},
				{
					status: { _eq: 'published' }
				},
				...unsupportedFiltersPayload,
				{
					beef_product_id: {
						status: 'published'
					}
				},
				...(
					retailerId ?
						[
							{
								owner: { _eq: retailerId }
							}
						] : []
				)
			]
		};

		const res = await axios.get(`${baseUrl}/items/barcode`, {
			params: { access_token: token, filter, fields: "*,beef_product_id.*.*,owner.*,owner.translations.*" },
		});
		return res.data.data;
	} catch (error) {
		throw error?.response?.data?.errors;
	}
};

const getVideoClassroom = async (seachString = null, filterParams = {}) => {
	try {
		const params = {
			access_token: token,
			fields: "*,translations.*"
		};

		if (seachString) {
			params.search = seachString
		}

		const res = await axios.get(`${baseUrl}/items/video_library/`, {
			params: {...params, ...filterParams},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getVideo = async (videoId) => {
	try {
		const params = {
			access_token: token,
			fields: "*,translations.*"
		};

		const res = await axios.get(`${baseUrl}/items/video_library/${videoId}`, {
			params
		});

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getBrand = async () => {
	try {
		const res = await axios.get(`${baseUrl}/items/brand/`, {
			params: { access_token: token },
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getQRCode = async (filterParams = {}) => {
	try {
		const res = await axios.get(`${baseUrl}/items/qr/`, {
			params: {
				...{ access_token: token },
				...filterParams
			}
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const getNutrition = async (nutritionId) => {
	try {
		const res = await axios.get(`${baseUrl}/items/nutrition/${nutritionId}`, {
			params: {
				access_token: token,
				fields: "*,translations.*",
			},
		});
		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const postQRCodeAnalytics = async (payload = {}) => {
	try {
		const res = await axios.post(`${baseUrl}/items/qr_code_analytics/`, payload, {
			params: { access_token: token },
		});

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const updateRecipeTranslation = async (payload = {}) => {
	try {
		const res = await axios.patch(
			`${baseUrl}/items/recipe_translations/${payload.id}`,
			payload, {
				params: { access_token: 'xI8Sh_wG44TuWYZ39aNorVyObJWlYfR1' },
			}
		);

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const postMessage = async (payload = {}) => {
	try {
		const res = await axios.post(`${baseUrl}/items/messages/`, payload, {
			params: { access_token: token },
		});

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const postLoadPageViewAnalytics = async (payload = {}) => {
	try {
		const res = await axios.post(`${baseUrl}/items/page_views/`, payload, {
			params: { access_token: token },
		});

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const postLog = async (payload = {}) => {
	try {
		const res = await axios.post(`${baseUrl}/items/logs/`, payload, {
			params: { access_token: token },
		});

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const postBarcodeScan = async (payload = {}) => {
	try {
		const res = await axios.post(`${baseUrl}/items/scans/`, payload, {
			params: { access_token: token },
		});

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const postBarcode = async (payload = {}) => {
	try {
		const res = await axios.post(`${baseUrl}/items/barcode/`, payload, {
			params: { access_token: token },
		});

		return res.data.data;
	} catch (error) {
		throw handleResponseError(error);
	}
};

const  handleResponseError = (error) => {
	let errorMessages = [];

	if (error.response) {
		errorMessages = [...error.response?.data?.errors, ...errorMessages];
	} else if (error.request) {
		errorMessages.push({
			message: 'Network error'
		});
	} else {
		errorMessages.push({
			message: error.message || 'Failed to setup request'
		});
	}
	return errorMessages;
};

export default {
	getAuthor,
	getBeefProduct,
	getCollection,
	getCookingMethod,
	getDefaults,
	getBeefProductBrand,
	getListCoupons,
	getListNutrientLabels,
	getCookingMethods,
	getLanguages,
	getListCategory,
	getListMenus,
	getListProducts,
	getListProvinces,
	getListRecipe,
	getListRedirectSites,
	getListRetailer,
	getMenu,
	getGenericRetailer,
	getBarcode,
	getPromotedProducts,
	getQRCode,
	getVideo,
	getVideoClassroom,
	getRecipe,
	getBrand,
	getNutrition,
	postQRCodeAnalytics,
	postLoadPageViewAnalytics,
	postLog,
	postBarcode,
	postBarcodeScan,
	postMessage,
	reverseGeocode,
	searchArticles,
	searchBeefCuts,
	searchCollections,
	searchRecipes,
	searchRecipeAuthors,
	searchTags,
	geolocateUsingIp,
	updateRecipeTranslation
};
