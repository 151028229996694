import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { getTranslation } from '../../utils/translate';

import DescriptionHolder from '../DescriptionHolder';

import './Nutrition.scss';
import { Grid } from '@mui/material';
import QNCCharts from '../QNCCharts';

const Nutrition = ({ nutrition, recipe = {}, showAllergens = true }) => {
  const language = useSelector(state => state.language);
  const { t } = useTranslation(['translations', 'nutrition']);

  const translatedNutrition = getTranslation(nutrition, { key: 'languages_code', code: language.code });
  const frenchTranslatedNutrition = getTranslation(nutrition, { key: 'languages_code', code: 'fr-FR' });

  const bilingualTranslate = (key, ns) => {
    const nsObject = ns ? {
      ns: ns
    } : {};

    return t(key, { lng: 'en-US', ...nsObject }) + ' / ' + t(key, { lng: 'fr-FR', ...nsObject });
  }

  const buildFatChildData = () => {
    // set defaults
    let saturated = {
      label: 'Saturated / saturés',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    let trans = {
      label: 'Trans / trans',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    // check if values exist
    const saturatedFats = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0012-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));
    const transFats = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0015-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));

    // build label
    if (!!saturatedFats?.length) {
      saturated = {
        ...saturatedFats[0],
        label: 'Saturated / saturés',
      };
    }

    if (!!transFats?.length) {
      trans = {
        ...transFats[0],
        label: '+ Trans / trans',
      };
    }

    const saturatedMass = parseFloat(saturated.value) || 0;
    const transMass = parseFloat(trans.value) || 0;

    // add %s
    return {
      label: (
        saturated.label + ' ' + saturated.value + ' ' + saturated.unit + "<br />" 
        + trans.label + ' ' + trans.value + ' ' + trans.unit
      ),
      value: 0,
      unit: '',
      percentage: (
        saturatedMass + transMass
      ) / 20 * 100
    }
  }

  const buildCarbohydratesChildData = () => {
    // set defaults
    let fibre = {
      label: 'Fibre / Fibres',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    let sugar = {
      label: 'Sugars / Sucres',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    // check if values exist
    const fibres = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0003-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));
    const sugars = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0006-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));

    // build label
    if (!!fibres?.length) {
      fibre = fibres[0];
    }

    if (!!sugars?.length) {
      sugar = sugars[0];
    }

    // add %s
    return [
      {
        ...fibre,
        label: 'Fibre / Fibres',
      },
      {
        ...sugar,
        label: 'Sugars / Sucres',
      }
    ]
  }

  const setClaim = (nutritionId, percentage) => {
    let claimWording = 'Source';

    const claimRules = [
      {
        minDV: 5,
        maxDV: 14,
        claim: 'Source',
      },
      {
        minDV: 15,
        maxDV: 24,
        claim: 'GoodSource',
      },
      {
        minDV: 25,
        maxDV: 1000000, // make ridiculously high
        claim: 'ExcellentSource',
      }
    ];

    const vitaminCClaimRules = [
      {
        minDV: 5,
        maxDV: 29,
        claim: 'Source',
      },
      {
        minDV: 30,
        maxDV: 49,
        claim: 'GoodSource',
      },
      {
        minDV: 50,
        maxDV: 1000000, // make ridiculously high
        claim: 'ExcellentSource',
      }
    ];

    // if vitamin C
    if (nutritionId === '84a8709a-0025-0000-ebf9-90cea7d9d44f') {
      vitaminCClaimRules.forEach(claimRule => {
        // if percentage falls with min & max DV limits then assign claim
        if (percentage >= claimRule.minDV && percentage <= claimRule.maxDV) {
          claimWording = claimRule.claim;
          return;
        }
      })
    } else {
      claimRules.forEach(claimRule => {
        // if percentage falls with min & max DV limits then assign claim
        if (percentage >= claimRule.minDV && percentage <= claimRule.maxDV) {
          claimWording = claimRule.claim;
          return;
        }
      })
    }

    return claimWording;
  }

  const getMicronutrient = (id) => {
    const nutrients = frenchTranslatedNutrition?.micronutrients?.filter(nutrient => [id].includes(nutrient.id));

    if (!!nutrients?.length) {
      return nutrients[0];
    } else {
      return {};
    }
  }

  const proteinIds = ['84a8709a-0001-0000-ebf9-90cea7d9d44f'];
  const fatIds = ['84a8709a-0011-0000-ebf9-90cea7d9d44f'];
  const carbohydrateIds = ['84a8709a-0002-0000-ebf9-90cea7d9d44f'];
  const sodiumIds = ['84a8709a-003c-0000-ebf9-90cea7d9d44f'];
  const cholesterolIds = ['84a8709a-0016-0000-ebf9-90cea7d9d44f'];
  const coreMicronutrientIds = [
    '84a8709a-003a-0000-ebf9-90cea7d9d44f',
    '84a8709a-002f-0000-ebf9-90cea7d9d44f',
    '84a8709a-0035-0000-ebf9-90cea7d9d44f'
  ];
  const mineralIds = [
    '84a8709a-003c-0000-ebf9-90cea7d9d44f', // Sodium
    '84a8709a-003a-0000-ebf9-90cea7d9d44f', // Potassium
    '84a8709a-002f-0000-ebf9-90cea7d9d44f', // Calcium
    '84a8709a-0035-0000-ebf9-90cea7d9d44f', // Iron
    '84a8709a-0039-0000-ebf9-90cea7d9d44f', // Phosphorus
    '84a8709a-0036-0000-ebf9-90cea7d9d44f', // Magnesium
    '84a8709a-003d-0000-ebf9-90cea7d9d44f', // Zinc
    '84a8709a-003b-0000-ebf9-90cea7d9d44f', // Selenium
    '84a8709a-0032-0000-ebf9-90cea7d9d44f', // Copper
    '84a8709a-0037-0000-ebf9-90cea7d9d44f', // Manganese
    '84a8709a-0034-0000-ebf9-90cea7d9d44f', // Iodine
    '84a8709a-0030-0000-ebf9-90cea7d9d44f', // Chloride
    '84a8709a-0038-0000-ebf9-90cea7d9d44f', // Molybdenum
    '84a8709a-0031-0000-ebf9-90cea7d9d44f', // Chromium
    '84a8709a-0033-0000-ebf9-90cea7d9d44f', // Fluoride
  ];
  let qncData = {
    minerals: [],
    vitamins: []
  }

  if(translatedNutrition.micronutrients)
  {
  qncData = {
    minerals: [
      ...(
        translatedNutrition?.micronutrients
          ?.filter(micronutrient => mineralIds.includes(micronutrient.id))
          ?.filter(micronutrient => micronutrient.percentage >= 5)
          ?.map(micronutrient => ({
            name: t(micronutrient.label, { ns: 'nutrition' }),
            value: micronutrient.percentage || 0,
            quality: setClaim(micronutrient.id, micronutrient.percentage),
          }))
      )
    ],
    vitamins: [
      ...(
        translatedNutrition?.micronutrients
          ?.filter(micronutrient => !mineralIds.includes(micronutrient.id))
          ?.filter(micronutrient => micronutrient.percentage >= 5)
          ?.map(micronutrient => ({
            name: t(micronutrient.label, { ns: 'nutrition' }),
            value: micronutrient.percentage || 0,
            quality: setClaim(micronutrient.id, micronutrient.percentage),
          }))
      )
    ],
  };
} 

  const proteinChildren = translatedNutrition?.macronutrients?.filter(nutrient => proteinIds.includes(nutrient.id)) || [];
  const fatChildren = [
    ...(translatedNutrition?.macronutrients?.filter(nutrient => fatIds.includes(nutrient.id)) || []),
    buildFatChildData()
  ];

  const carbohydratesChildren = [
    ...(translatedNutrition?.macronutrients?.filter(nutrient => carbohydrateIds.includes(nutrient.id)) || []),
    ...buildCarbohydratesChildData()
  ];
  
  const sodiumChildren = translatedNutrition?.macronutrients?.filter(nutrient => sodiumIds.includes(nutrient.id));
  const cholesterolChildren = translatedNutrition?.macronutrients?.filter(nutrient => cholesterolIds.includes(nutrient.id));

  const protein = proteinChildren?.splice(0, 1)[0] || {};
  const fat = fatChildren?.splice(0, 1)[0] || {};
  const carbohydrates = carbohydratesChildren?.splice(0, 1)[0] || {};
  const sodium = sodiumChildren?.splice(0, 1)[0] || {};
  const cholesterol = cholesterolChildren?.splice(0, 1)[0] || {};

  const nutritionalInformation = {
    macro: [
      {
        label: bilingualTranslate('nutrition_fat'),
        mass: !isNaN(fat?.value) ? (fat?.value + ' ' + fat?.unit) : '0 g',
        percentage: !isNaN(fat?.percentage) ? `${Math.round(fat?.percentage)} %` : '0 %',
        children: fatChildren
      },
      {
        label: bilingualTranslate('nutrition_carbohydrates'),
        mass: !isNaN(carbohydrates?.value) ? (carbohydrates?.value + ' ' + carbohydrates?.unit) : '0 g',
        percentage: !isNaN(carbohydrates?.percentage) ? `${Math.round(carbohydrates?.percentage)} %` : '',
        children: carbohydratesChildren
      },
      {
        label: bilingualTranslate('nutrition_protein'),
        mass: !isNaN(protein?.value) ? (protein?.value + ' ' + protein?.unit) : '0 g',
        percentage: !isNaN(protein?.percentage) ? `${Math.round(protein?.percentage)} %` : '',
        children: proteinChildren
      },
      {
        label: bilingualTranslate('nutrition_cholesterol'),
        mass: !isNaN(cholesterol?.value) ? (cholesterol?.value + ' ' + cholesterol?.unit) : '0 mg',
        percentage: !isNaN(cholesterol?.percentage) ? `${Math.round(cholesterol?.percentage)} %` : '',
        children: cholesterolChildren
      },
      {
        label: t('nutrition_sodium', { lng: 'en-US'}),
        mass: !isNaN(sodium?.value) ? (sodium?.value + ' ' + sodium?.unit) : '0 mg',
        percentage: !isNaN(sodium?.percentage) ? `${Math.round(sodium?.percentage)} %` : '0 %',
        children: sodiumChildren
      },
    ],
    coreMicronutrients: [
      {
        id: '84a8709a-003a-0000-ebf9-90cea7d9d44f',
        label: t('Potassium', {ns: 'nutrition'})
      },
      {
        id: '84a8709a-002f-0000-ebf9-90cea7d9d44f',
        label: t('Calcium', { ns: 'nutrition' })
      },
      {
        id: '84a8709a-0035-0000-ebf9-90cea7d9d44f',
        label: bilingualTranslate('Iron', 'nutrition'),
      }
    ],
    nonCoreMicronutrients: [
      ...(
        translatedNutrition?.micronutrients
          ?.filter(nutrient => (!coreMicronutrientIds.includes(nutrient.id) && nutrient.percentage > 0)) || []
      )
    ]
  };
  
  const getPortionSize = () => {
    // if this is a beef cut then show 100g
    let portionSize = `${t('per')} ${(translatedNutrition.portion_size || '100 g')} ${t('cooked')}`;

    // if this is a recipe then build portion size message
    if (!!recipe.id) {
      portionSize = t('perServing') + ' (' + (translatedNutrition.portion_size || '0 g') + ')'
    }
    
    return portionSize;
  }

  return (
    <Box
      className='nutrition'
    >
      <Box>
        {
          !!translatedNutrition?.description &&
          <Typography 
              className='search-message'
              sx={{
                fontWeight: 300,
                fontSize: '18px'
              }}
              component="div">
              <DescriptionHolder
                content={translatedNutrition?.description}
                allowedTags={['img']}
              />
          </Typography>
        }

        <Stack>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            sx={{
              mt: 1
            }}
          >
            <Grid
              className='no-print'
              item
              xs={12}
              md={8}
              lg={7}
            >
              <QNCCharts
                cndData={qncData}
                portionSize={getPortionSize()}
              />

              {!!recipe.id &&
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    textAlign: 'justify'
                  }}
                >
                  <i>{t('qnc_recipeDisclaimer')}</i>
                </Typography>
              }

              {!!!recipe.id &&
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    textAlign: 'justify',
                  }}
                >
                  <i>{nutrition.notes}</i>
                </Typography>
              }
            </Grid>

            <Grid item xs={12} md={8} lg={7}>
              <Box
                className="table"
                sx={{
                  background: '#fff'
                }}
              >
                <Box className="table_title">{t('nutrition_facts', { lng: 'en-US' })}</Box>
                <Box className="table_title">{t('nutrition_facts', { lng: 'fr-FR' })}</Box>
                <Box
                  className="table_serving-size border-btm-slim"
                >
                  <Box className="table_serving">
                    {!!recipe.id ? t('perServing', { lng: 'en-US' }) : t('per', { lng: 'en-US' })}&nbsp;
                    {!!recipe.id ? '(' + (translatedNutrition.portion_size || nutrition.portion_size || '0 g') + ')' : translatedNutrition.portion_size || '100 g' }&nbsp;
                    {!!recipe.id ? '' : t('cooked', { lng: 'en-US' }) }
                  </Box>
                  <Box className="table_serving">
                    {!!recipe.id ? t('perServing', { lng: 'fr-FR' }) : t('per', { lng: 'fr-FR' }) }&nbsp;
                    {!!recipe.id ? '(' + (translatedNutrition.portion_size || nutrition.portion_size || '0 g') + ')' : translatedNutrition.portion_size || '100 g' }&nbsp;
                    { !!recipe.id ? '' : t('cooked', { lng: 'fr-FR' }) }
                  </Box>
                </Box>

                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={1}
                >
                  <Typography
                    className="table_block-calories"
                    sx={{
                      fontSize: {
                        xs: '1.1rem',
                        md: '1.35rem',
                        lg: '1.75rem',
                      },
                      fontWeight: 700
                    }}
                    variant={'span'}
                  >
                    {t('nutrition_calories')} {nutrition.calories}
                  </Typography>
                  <Stack
                    align="right"
                    sx={{
                      textAlign: 'right',
                      p: 0,
                      fontSize: '0.75rem',
                      lineHeight: 'unset',
                      borderBottom: 'none',
                      fontWeight: 'bold'
                    }}
                  >
                    <Box>{t('nutrition_dv*', { lng: 'en-US' })}</Box>
                    <Box>{t('nutrition_dv*', { lng: 'fr-FR' })}</Box>
                  </Stack>
                </Stack>

                <Box>
                  <TableContainer>
                    <Table
                      sx={{
                        borderCollapse: 'unset'
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableBody>
                        {
                          nutritionalInformation.macro.map((macroNutrient) => (
                              <React.Fragment
                                key={macroNutrient.label}
                              >
                                <TableRow
                                  className='border-btm-slim'
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      p: 0,
                                      borderBottom: !macroNutrient?.children?.length ? '3px solid black' : 'none'
                                    }}
                                  >
                                    <strong>
                                      {macroNutrient.label}
                                    </strong>
                                    &nbsp;{macroNutrient.mass}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      p: 0,
                                      borderBottom: !macroNutrient?.children?.length ? '3px solid black' : 'none'
                                    }}
                                  >
                                    {`${macroNutrient.percentage}`}
                                  </TableCell>
                                </TableRow>

                                {
                                  macroNutrient?.children?.map((row, index) => (
                                    <TableRow
                                      key={row.label}
                                      className=''
                                    >
                                      <TableCell
                                        sx={{
                                          p: 0,
                                          pl: 1,
                                          borderBottom: 
                                            (macroNutrient?.children?.length - 1) ===  index ?
                                            '3px solid black' :
                                            'none'
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        {
                                          !!row.value ?
                                          <DescriptionHolder content={(row.label + ` ${!isNaN(row.value) ? (row.value) : 0} ${row.unit || 'g'}`)} /> :
                                          <DescriptionHolder content={(row.label)} />
                                        }
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        sx={{
                                          p: 0,
                                          borderBottom:
                                            (macroNutrient?.children?.length - 1) === index ?
                                              '3px solid black' :
                                              'none'
                                        }}
                                      >
                                        { !isNaN(row.percentage) ? ` ${Math.round(row.percentage)} %` : '0 %' }
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              </React.Fragment>
                            )
                          )
                        }

                        {
                          nutritionalInformation.coreMicronutrients
                            .map((row) => (
                              <TableRow
                                className='border-btm-slim'
                                key={row.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    borderBottom: '3px solid black'
                                  }
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    p: 0,
                                    borderBottom: 'none'
                                  }}
                                >
                                  {row.label}
                                  &nbsp;
                                  {
                                    !isNaN(getMicronutrient(row.id)?.value) ?
                                    Math.round(getMicronutrient(row.id).value * 10) / 10 : 
                                    0
                                  }
                                  &nbsp;
                                  { getMicronutrient(row.id).unit || 'mg' }
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    p: 0,
                                    borderBottom: 'none'
                                  }}
                                >
                                  { !isNaN(getMicronutrient(row.id)?.percentage) ? `${Math.round(getMicronutrient(row.id)?.percentage)} %` : '0 %' }
                                </TableCell>
                              </TableRow>
                          ))
                        }

                        {
                          nutritionalInformation.nonCoreMicronutrients
                            .map((row) => (
                              <TableRow
                                className='border-btm-slim'
                                key={row.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    borderBottom: '3px solid black'
                                  }
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    p: 0,
                                    borderBottom: 'none'
                                  }}
                                >
                                  {t(row.label, { ns: 'nutrition', lng: 'en-US' })}
                                  {t(row.label, { ns: 'nutrition', lng: 'fr-FR', defaultValue: 'NOT_FOUND' }) !== 'NOT_FOUND' ? ` / ` + t(row.label, { ns: 'nutrition', lng: 'fr-FR', defaultValue: 'NOT_FOUND' }) : ''}
                                  &nbsp;
                                  {
                                    !isNaN(row?.value) ?
                                    Math.round(row.value * 10) / 10 : 
                                    0
                                  }
                                  &nbsp;
                                  { row.unit || 'mg' }
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    p: 0,
                                    borderBottom: 'none'
                                  }}
                                >
                                  { !isNaN(row.percentage) ? `${Math.round(row.percentage)} %` : '0 %' }
                                </TableCell>
                              </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box className="table_block-footer">
                  <Box className="text">
                    <Trans>
                      {t('nutrition_dv', { lng: 'en-US' })}
                    </Trans>
                  </Box>
                  <Box className="text">
                    <Trans>
                      {t('nutrition_dv', { lng: 'fr-FR' })}
                    </Trans>
                  </Box>
                </Box>
              </Box>

              {
                showAllergens &&
                translatedNutrition?.allergies?.length &&
                <Box>
                  <Typography
                    sx={{
                      fontSize: '0.9rem',
                      textAlign: 'justify'
                    }}
                  >
                    <i>
                      <strong>
                        {t('nutrition_allergens')}: &nbsp;
                      </strong>
                      {
                        translatedNutrition?.allergies?.join(', ')
                      }
                    </i>
                  </Typography>

                  <Typography
                    sx={{
                      pt: 0.5,
                      fontSize: '0.9rem',
                      textAlign: 'justify'
                    }}
                  >
                    <i>
                      {
                        t('allergen_disclaimer')
                      }
                    </i>
                  </Typography>
                </Box>
              }
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  )
};

Nutrition.propTypes = {
  nutrition: PropTypes.object.isRequired,
};

export default Nutrition;
