import { Box } from "@mui/material";
import { useContext } from "react";
import RetailerContext from "../../../context";
import BeefProductTile from "../../BeefProductTile";
import FullWidthCard from "../../FullWidthCard";
import BeefProductBrand from "../../BeefProductBrand";

function BeefBrands({beefBrands=[]}) {
    const { retailer } = useContext(RetailerContext);

    // filter out brands from other retailers
    const filteredBrands = beefBrands
        .filter(beefBrand => beefBrand?.status?.toLowerCase() === 'published')
        .filter(beefBrand => {
            return (
                beefBrand?.retailer === retailer.id ||
                !!beefBrand?.retailers?.find(retailerJunction => retailerJunction?.retailer_id === retailer.id)
            )
        });

    return (
        <Box
            fixed
            className='padding-on-mobile'
            sx={{}}
        >
            {
                filteredBrands?.map((beefBrand) => (
                    <BeefProductBrand
                        key={beefBrand.id}
                        item={beefBrand}
                        to={`/beef-product-brand/${beefBrand.id}`}
                    />
                ))
            }
        </Box>
    )
}

export default BeefBrands