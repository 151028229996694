
import React, {useState, useEffect, Fragment} from 'react';
import { useTranslation } from 'react-i18next';

import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, Tooltip, BarElement, Legend } from "chart.js";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import './index.scss';
import { Box, Stack } from '@mui/system';
import { t } from 'i18next';

ChartJS.register(
    ArcElement, 
    CategoryScale, 
    LinearScale, 
    Tooltip, 
    BarElement, 
    Legend,
    ChartDataLabels
);

function QNCCharts({cndData, portionSize = '100 g'}) {
  const { t } = useTranslation(['translations','nutrition']);
  let highestValue = 100;

  let namesMinerals = [];
    let valuesMinerals = [];
    let soucersMinerals = [];
    let valuesWhiteBarMinerals = [];
    let valuesOver100Minerals = [];

    let namesVitamins = [];
    let valuesVitamins = [];
    let soucersVitamins = [];
    let valuesWhiteBarVitamins = [];
    let valuesOver100Vitamins = [];

    const barPercentage = 0.9;
    const categoryPercentage = 1;

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    const [changedHeight, setChangedHeight] = useState(0);

    const [chartOptions, setChartOptions] = useState({});

    const [chartData2, setChartData2] = useState({
        datasets: [],
    });

    const checkForColor = (sources) => {
      let coloursArray = [];

      sources.map((source) => {
          if(source === "Source"){
            coloursArray.push('#ccc2a9');
          } else if(source === "GoodSource"){
            coloursArray.push('#e37c31');
          } else {
            coloursArray.push('#91241d');
          }
      });
      return coloursArray;
    }

    const checkForColourPercentage = (type) => {
      const colourPercentageArray = [];
      const offsetValues =  checkOffSetValues(type);

      offsetValues.forEach(offsetValue => {
        // this means text will be on top of bar so it needs to be white
        if (offsetValue !== 0) {
          colourPercentageArray.push('white');
        } else {
          colourPercentageArray.push('black');
        }
      })

      return colourPercentageArray;
    }

    const checkOffSetValues = (type) => {
      const offSetArray = [];
      
      // offset value should be increased when the number of digits increases
      const offsetLevel1 = 0;
      const offsetLevel2 = -35;
      const offsetLevel3 = -43;
      const offsetLevel4 = -51;
      const maxGap = 20;

      if(type==='minerals'){
        valuesMinerals.map((values) => {
          if(values < 15){
            offSetArray.push(offsetLevel1);
          } else if(values >= 15 && values <= 35) {

            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(offsetLevel2);
            }

          } else if(values > 35 && values <= 99) {

            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(offsetLevel2);
            }

          } else if(values > 99 && values <= 999) {

            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(offsetLevel4);
            }

          } else{

            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(offsetLevel4);
            }

          }
        })
      } else {
        valuesVitamins.map((values) => {
          if(values < 15){
            offSetArray.push(offsetLevel1);
          } else if(values >= 15 && values <= 35) {

            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(offsetLevel2);
            }

          } else if(values > 35 && values <= 99) {

            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(offsetLevel2);
            }
            
          } else if(values > 99 && values <= 999) {

            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(offsetLevel4);
            }

          } else{
            if (highestValue - values > maxGap) {
              offSetArray.push(offsetLevel1);
            } else {
              offSetArray.push(-60);
            }

          }
        })
      }
      
      return offSetArray;
    }

    const calculateCanvasHeight = (chartData) => {
      const barThickness = 40;

      let canvasHeight = 0;

      canvasHeight = (chartData?.labels?.length || 0) * barThickness;

      return canvasHeight;
    }

    const loadData = () => {
      cndData.minerals
        .sort((a, b) => b.value - a.value)
        .map((item, index) => {
          namesMinerals.push(item.name);

          if (item.value > 100 ){
              valuesMinerals.push(100);
              valuesWhiteBarMinerals.push(0);
              valuesOver100Minerals.push(0);
          } else {
              valuesMinerals.push(item.value);
              valuesWhiteBarMinerals.push(0);
              valuesOver100Minerals.push(0);
          }

          soucersMinerals.push(item.quality);
        });

      cndData.vitamins
        .sort((a, b) => b.value - a.value)
        .map((item, index) => {
          let trimmedName = item.name;
          
          // split at '-' and get 2nd part
          let splitName = item.name.split('-');

          if (splitName.length > 1) {
            trimmedName = splitName[1];

            trimmedName = trimmedName.trim();
          }

          // split at ' '
          splitName = trimmedName.split(' ');
          
          // get 1st part if not Vitamin
          trimmedName = splitName[0] !== t('Vitamin', {ns: 'nutrition'}) ? splitName[0] : trimmedName;

          // trim spaces again
          namesVitamins.push(trimmedName);

          if (item.value > 100) {
            valuesVitamins.push(100);
            valuesWhiteBarVitamins.push(0);
            valuesOver100Vitamins.push(0);
          } else {
            valuesVitamins.push(item.value);
            valuesWhiteBarVitamins.push(0);
            valuesOver100Vitamins.push(0);
          }

          soucersVitamins.push(item.quality);
        });
    }

    useEffect(() => {
      loadData();
      
      setChartData({
        labels: namesMinerals,
        datasets: [
          {
            data: valuesMinerals,
            borderColor: 'white',
            barPercentage,
            categoryPercentage,
            backgroundColor: checkForColor(soucersMinerals),
            datalabels: {
              display: true,
              color: checkForColourPercentage('minerals'),
              anchor: 'end',
              align: 'right',
              offset: checkOffSetValues('minerals'),
              font: {
                weight: 'bold',
                size: 12,
              },
              formatter: function(value, context){
                return cndData.minerals[context.dataIndex].value + '%';
              }
            }
          },
          {
            data: valuesWhiteBarMinerals,
            borderColor: 'white',
            barPercentage,
            categoryPercentage,
            backgroundColor: 'white',
            datalabels: {
              display: false
            }
          },
          {
            data: valuesOver100Minerals,
            borderColor: 'white',
            barPercentage,
            categoryPercentage,
            backgroundColor: '#91241d',
            datalabels: {
              display: false,
              color: 'white'
            }
          },
        ],
      });

      setChartData2({
        labels: namesVitamins,
        datasets: [
          {
            data: valuesVitamins,
            borderColor: 'white',
            barPercentage,
            categoryPercentage,
            backgroundColor: checkForColor(soucersVitamins),
            label: '',
            datalabels: {
              display: true,
              color: checkForColourPercentage('vitamins'),
              anchor: 'end',
              align: 'right',
              offset: checkOffSetValues('vitamins'),
              font: {
                weight: 'bold',
                size: 12,
              },
              formatter: function(value, context){
                return cndData.vitamins[context.dataIndex].value + '%';
              }
            }
          },
          {
            data: valuesWhiteBarVitamins,
            borderColor: 'white',
            barPercentage,
            categoryPercentage,
            backgroundColor: 'white',
            datalabels: {
              display: false,
              color: 'black',
              font: {
                  weight: 'bold'
              },
              padding: {
                  top: 10,
                  bottom: 10
              }
            }
          },
          {
            data: valuesOver100Vitamins,
            borderColor: 'white',
            barPercentage,
            categoryPercentage,
            backgroundColor: checkForColor(soucersVitamins),
            datalabels: {
              display: false,
              color: 'white',
              font: {
                  weight: 'bold'
              },
              padding: {
                  top: 10,
                  bottom: 10
              }
            }
          },
        ],
      });

      setChartOptions({
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            stacked: true,
            min: 0,
            max: highestValue,
            ticks: {
              callback: function(value, index, ticks) {
                return `${value}%`;
              }
            }
          },
          y: {
            stacked: true,
            ticks: {
        	    crossAlign: 'start'
            },
            afterFit(scale) {
              scale.width = 80;
            },
          },
        },
        plugins: {
          legend: {
              display: false
          },
        },
        hover: {mode: null},
        events: ['click'],
      });

      function handleResize() {
        setChangedHeight(window.innerWidth);
      }
  
      window.addEventListener('resize', handleResize);
    },[cndData]);

  return (
    <Box
      className={'qnc'}
      sx={{
        background: '#fff'
      }}
    >
      <Box
        className='cardContainer'
      >
        <h2>{portionSize} - { t('qnc_dvMessage') }</h2>
        <Stack
          className=''
          direction={'row'}
          spacing={1}
          justifyContent={'center'}
        >
          <Stack
            className='qualititySourceItem'
            direction={'row'}
            alignItems={'center'}
          >
              <Box style={{backgroundColor: '#91241d'}}></Box>
              <p>{ t('qnc_sourceExcellent') }</p>
          </Stack>

          <Stack
            className='qualititySourceItem'
            direction={'row'}
            alignItems={'center'}
          >
            <Box style={{ backgroundColor: '#e37c31'}}></Box>
            <p>{ t('qnc_sourceGood') }</p>
          </Stack>

          <Stack
            className='qualititySourceItem'
            direction={'row'}
            alignItems={'center'}
          >
            <Box style={{ backgroundColor: '#ccc2a9'}}></Box>
            <p>{ t('qnc_source') }</p>
          </Stack>
        </Stack>

        {
          !!calculateCanvasHeight(chartData) &&
          <Fragment>
            <hr style={{margin: 0}} />
            <h2 style={{margin: 0, textAlign: 'left'}}>{ t('qnc_minerals') }</h2>
            <hr style={{margin: 0}} />
            <Box
              sx={{
                height: calculateCanvasHeight(chartData) + 60,
                paddingRight: '1rem'
              }}
            >
              <Bar
                options={chartOptions}
                data={chartData}
                plugins={
                  [
                    {
                      id: 'whiteBackground',
                      beforeDatasetsDraw(chart, args, pluginOptions) {
                        const {
                          ctx,
                          chartArea: {
                            top,
                            bottom,
                            left,
                            right,
                            width,
                            height
                          }
                        } = chart;

                        ctx.save();
                        ctx.fillStyle = 'white';
                        ctx.fillRect(left, top, width, height)
                      }
                    }
                  ]
                }
              />
            </Box>
          </Fragment>
        }

        {
          !!calculateCanvasHeight(chartData2) &&
          <Fragment>
            <hr style={{margin: 0}} />
            <h2 style={{margin: 0, textAlign: 'left'}}>{ t('qnc_vitamins') }</h2>
            <hr style={{margin: 0}} />
            <Box
              sx={{
                height: calculateCanvasHeight(chartData2) + 60,
                paddingRight: '1rem'
              }}
            >
              <Bar options={chartOptions} data={chartData2} plugins={[{id: 'whiteBackground', beforeDatasetsDraw(chart, args, pluginOptions){const { ctx, chartArea: { top, bottom, left, right, width, height } } = chart; ctx.save(); ctx.fillStyle = 'white'; ctx.fillRect(left, top, width, height)}}]} />
            </Box>
          </Fragment>
        }
      </Box>
    </Box>
  )
}

export default QNCCharts;