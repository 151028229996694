import { Container, Divider, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import RetailerContext from '../../context';

import './Footer.scss';

import ScanImage from '../../shared/img/scan.webp';
import ScanImageWhite from '../../shared/img/scan-white.png';
import { Box } from '@mui/material';
import Menu from '../Menu';
import api from '../../api';
import notify from '../Notification/helper';
import { hexToRgb, isLight } from '../../utils/colour';

const Footer = () => {
  const { t } = useTranslation();
  const retailerContext = useContext(RetailerContext);

  const [footerMenuId, setFooterMenuId] = useState(null);
  const [bottomFooterMenuId, setBottomFooterMenuId] = useState(null);

  useEffect(async () => {
    try {
      const defaultsResponse = await api.getDefaults();

      if (retailerContext.retailer.footer_menu) {
        setFooterMenuId(retailerContext.retailer.footer_menu);
      } else {
        setFooterMenuId(defaultsResponse.processor_footer_menu)
      }

      if (retailerContext.retailer.footer_menu_bottom) {
        setBottomFooterMenuId(retailerContext.retailer.footer_menu_bottom);
      } else {
        setBottomFooterMenuId(defaultsResponse.processor_footer_menu_bottom)
      }
    } catch (error) {
      error.map((err) => notify('error', err.message));
    }
  }, []);

  return (
    <Box
      className='no-print'
      sx={{
        mt: 8,
        width: '100%',
        background: '#000'
      }}
    >
      <Container
        className='padding-on-mobile'
      >
        {
          !!footerMenuId &&
          <Menu 
            id={footerMenuId}
            variant={'inline'}
          />
        }
        
        <Box
          sx={{
            py: 2,
            textAlign: 'justify'
          }}
        >
          <Typography
            variant='span'
            sx={{
              color: '#ffffff',
              fontSize: '0.8em'
            }}
            >
              {t('disclaimer')}
            </Typography>
        </Box>

        {
          !!bottomFooterMenuId &&
          <Menu
            id={bottomFooterMenuId}
            variant={'inline'}
          />
        }
      </Container>

      {
        retailerContext.retailer.allow_scanning &&
        <Link className="footer-btn" to="/scanner">
          <div className="footer">
            <div style={{ position: 'relative', height: '100%' }}>
              <svg style={{ position: 'absolute', width: '100%', height: '100%' }}>
                <mask id='m' fill='#fff'>
                  <rect id='r' width='100%' height='100%' />
                  <circle id='c' r='20' fill='#000' />
                </mask>
                <use xlinkHref='#r' fill={retailerContext.retailer.button_background_color} mask='url(#m)' />
              </svg>
              <Stack
                alignItems={'center'}
                justifyContent={'center'}
                style={{ 'backgroundColor': 'transparent', position: 'absolute', width: '100%', height: '100%' }}
              >
                {
                  isLight(hexToRgb(retailerContext.retailer.button_background_color)) ?
                    <img src={ScanImage} style={{ width: '50%' }} alt="" /> :
                    <img src={ScanImageWhite} style={{ width: '50%' }} alt="" />
                }
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: isLight(hexToRgb(retailerContext.retailer.button_background_color)) ? '#000' : '#fff',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                  className="font-primary"
                >
                  {t('scanHere')}
                </Typography>
              </Stack>
            </div>
          </div>
        </Link>
      }
    </Box>
  )
};

export default Footer;
