import React from 'react';

import './NoResultsFound.scss';

import {
  HighlightOff,
} from '@mui/icons-material';

import {
  Box,
  Divider,
  Paper,
	Stack,
	Typography
} from "@mui/material";

import { green } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';

const NoResultsFound = () => {
  const { t } = useTranslation();

  return (
    <div className="app-wrapper">
      <Stack
        alignItems={'center'}
        sx={ { my: 3 } }
      >
        <HighlightOff fontSize="large" color="disabled" />
        <Typography color="disabled">
          { t('noResultsFound') }
        </Typography>
      </Stack>
    </div>
  );
};

export default NoResultsFound;
