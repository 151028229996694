import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      contact: require('./locales/en/contact.json'),
      faqs: require('./locales/en/faqs.json'),
      nutrition: require('./locales/en/nutrition.json'),
      provinces: require('./locales/en/provinces.json'),
      termsofuse: require('./locales/en/termsofuse.json'),
      translations: require('./locales/en/translations.json')
    },
    fr: {
      contact: require('./locales/fr/contact.json'),
      faqs: require('./locales/fr/faqs.json'),
      nutrition: require('./locales/fr/nutrition.json'),
      provinces: require('./locales/fr/provinces.json'),
      termsofuse: require('./locales/fr/termsofuse.json'),
      translations: require('./locales/fr/translations.json')
    }
  },
  ns: ['translations', 'faqs', 'nutrition', 'contact', 'termsofuse', 'provinces'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'fr'];

export default i18n;
