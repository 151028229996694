const filters = (state = {}, action) => {
    switch(action.type) {
        case 'filters/clear':
            return {};
        case 'filters/update':
            return {
                ...state,
                ...action.payload
            };
        default: 
            return state;
    }
};

export default filters;