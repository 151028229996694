import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import RetailerContext from '../../context';

import HomeIcon from '@mui/icons-material/Home';

import { Container, Stack, Typography } from '@mui/material';

import LanguageSwitcher from '../LanguageSwitcher';
import ProvinceSwitcher from '../ProvinceSwitcher';
import SiteSwitcher from '../SiteSwitcher';

import './Header.scss';
import { useRetailerSettings } from '../../hooks/useRetailerSettings';

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { retailer } = useContext(RetailerContext);

  const subdomain = process.env.NODE_ENV === 'production' ?
    window.location.hostname :
    process.env.REACT_APP_BASE_URL
  ;

  const {
    retailerSettings,
    error: retailerSettingsError,
    isLoading: retailerSettingsLoading
  } = useRetailerSettings(subdomain);

  const goToMenu = () => {
    history.push('/');
  };

  return (
    <Stack
      className='no-print'
      alignItems="center"
      justifyContent="center"
      sx={{ background: 'black', width: '100%', height: '72px' }}
      >
      <Container
        className='padding-on-mobile'
      >
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          {
            retailerSettings?.enable_home_button &&
            <Link to='/'>
              <Stack 
                direction="column"
                justifyContent="center"
                alignItems="center"
                onClick={goToMenu}
              >
                <HomeIcon sx={{ color: 'white', fontSize: 32 }} />
                <Typography sx={{
                  color: 'white',
                  fontSize: 12,
                  textTransform: 'uppercase'
                }}
              >
                { t('home') }
              </Typography>
              </Stack>
            </Link>
          }

          <Stack
            direction={'row'}
            spacing={1}
            sx={{
              ml: 'auto'
            }}
          >
            {
              !!retailer?.redirect_sites?.length &&
              <SiteSwitcher />
            }
            
            {
              !!retailer?.regions?.length &&
              <ProvinceSwitcher />
            }
            
            <LanguageSwitcher />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
export default Header;
