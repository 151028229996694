import { Box } from "@mui/material"
7
import { array, bool, func } from 'prop-types';
import ListItem from "../ListItem";

function ListView({ items, compact=true }) {
    return (
        <Box>
            {
                items?.map(item => (
                    <ListItem
                        key={item.url}
                        subtitle={item.subtitle}
                        title={item.title}
                        description={item.description}
                        image={item.image}
                        to={item.to}
                        url={item.url}
                        compact={compact}
                        handleClick={item.handleClick}
                    />
                ))
            }
        </Box>
    )
}

ListView.propTypes = {
    items: array.isRequired,
    compact: bool,
    handleResultClick: func
}

export default ListView;