import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Container, FormControl, OutlinedInput, Stack, TextField, Typography } from '@mui/material';

import BackButton from '../BackButton';
import Header from '../Header';

import './index.scss';

import HeroImage from '../../shared/img/contact.jpg';

import PageHeader from '../PageHeader';
import notify from '../Notification/helper';
import api from '../../api';
import Loader from '../Loader';
import RetailerContext from '../../context';
import { hexToRgb, isLight } from '../../utils/colour';

function Contact({ setLoading }) {
    const { t } = useTranslation(['translations', 'contact']);

    const { retailer } = useContext(RetailerContext);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [otherSubject, setOtherSubject] = useState('');
    const [content, setContent] = useState('');
    const [otherContent, setOtherContent] = useState('');

    const sendMessage = async () => {
        // validate fields
        if ([name, surname, email].includes('')) {
            notify('error', t('missingRequiredFields', { ns: 'contact' }));
            return;
        }

        if ([subject, content].includes('') && [otherSubject, otherContent].includes('')) {
            notify('error', t('missingRequiredIssueFields', { ns: 'contact' }));
            return;
        }

        // build payload
        const sendMessagePayload = {
            name,
            surname,
            email,
            subject,
            content,
            other_subject: otherSubject,
            other_content: otherContent
        }

        try {
            // make request
            setLoading(true);

            await api.postMessage(sendMessagePayload);

            // reset all fields
            setName('');
            setSurname('');
            setEmail('');
            setSubject('');
            setOtherSubject('');
            setContent('');
            setOtherContent('');

            // notify user 
            notify('success', t('messageSent', { ns: 'contact' }));
        } catch(error) {
            error.map((err) => notify("error", err.message));
        } finally {
            window.scrollTo(0, 0);
            setLoading(false);
        }
    }

    return (
        <Container
            className="contact"
        >
            <Container
                disableGutters
                sx={{
                    pt: 5
                }}
            >
                <Typography
                    className="faq-header"
                    variant='h3'
                    sx={{
                        fontWeight: 'bold'
                    }}
                >
                    {t('contact', { ns: 'contact' })}
                </Typography>

                <Typography sx={{ color: '#4D4D4D' }}>
                    {t('message', { ns: 'contact' })}
                </Typography>
            </Container>

            <Container
                disableGutters
                sx={{
                    my: 5
                }}
            >
                <Typography>
                    <i>
                        { t('requiredFieldsInstruction', { ns: 'contact'}) }
                    </i>
                </Typography>
                
                <Stack
                    sx={{
                        mb: 6
                    }}
                    spacing={2}
                >

                    <Typography
                        className='title'
                        variant={'h3'}
                        sx={{
                            pt: 5
                        }}
                    >
                        { t('fullName', { ns: 'contact' }) }
                    </Typography>

                    <Stack
                        direction={'row'}
                        spacing={1}
                    >
                        <Stack
                            sx={{
                                flexGrow: 1
                            }}
                        >
                            <FormControl variant="outlined">
                                <Typography>
                                    {t('name', { ns: 'contact' })}
                                    <strong>&nbsp;*</strong>
                                </Typography>
                                <OutlinedInput
                                    className="input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '42px',
                                        p: 1,
                                        width: '100%'
                                    }}
                                    value={name}
                                    onChange={(event) => { setName(event.target.value) }}
                                    variant="filled"
                                />
                            </FormControl>
                        </Stack>

                        <Stack
                            sx={{
                                flexGrow: 1
                            }}
                        >
                            <FormControl variant="outlined">
                                <Typography>
                                    {t('surname', { ns: 'contact' })}
                                    <strong>&nbsp;*</strong>
                                </Typography>
                                <OutlinedInput
                                    className="input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '42px',
                                        p: 1,
                                        width: '100%'
                                    }}
                                    value={surname}
                                    onChange={(event) => { setSurname(event.target.value) }}
                                    variant="filled"
                                />
                            </FormControl>
                        </Stack>
                    </Stack>

                    <Stack
                        sx={{
                            flexGrow: 1
                        }}
                    >
                        <FormControl variant="outlined">
                            <Typography
                                className='input-label'
                            >
                                {t('email', { ns: 'contact' })}
                                <strong>&nbsp;*</strong>
                            </Typography>

                            <OutlinedInput
                                className="input-field"
                                sx={{
                                    color: '#262626 !important',
                                    height: '42px',
                                    p: 1,
                                    width: '100%'
                                }}
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }}
                                variant="filled"
                                inputProps={{
                                    type: 'email'
                                }}
                                required
                            />
                        </FormControl>
                    </Stack>

                    <Typography
                        className='title'
                        variant={'h3'}
                        sx={{
                            pt: 5
                        }}
                    >
                        {t('technicalIssue', { ns: 'contact' })}
                    </Typography>

                    <Stack
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Typography
                            className='input-label'
                        >
                            {t('subject', { ns: 'contact' })}
                        </Typography>

                        <OutlinedInput
                            className="input-field"
                            sx={{
                                color: '#262626 !important',
                                height: '42px',
                                p: 1,
                                width: '100%'
                            }}
                            value={subject}
                            onChange={(event) => { setSubject(event.target.value) }}
                        />
                    </Stack>

                    <Stack
                        sx={{
                            flexGrow: 1
                        }}
                    >
                        <Typography
                            className='input-label'
                        >
                            {t('content', { ns: 'contact' })}
                        </Typography>

                        <TextField
                            className="input-field"
                            multiline
                            rows={8}
                            value={content}
                            onChange={(event) => { setContent(event.target.value) }}
                            variant="outlined"
                            sx={{
                                borderRadius: '0 !important'
                            }}
                        />
                    </Stack>

                    <Typography
                        className='title'
                        variant={'h3'}
                        sx={{
                            pt: 5
                        }}
                    >
                        {t('otherIssues', { ns: 'contact' })}
                    </Typography>

                    <Stack
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Typography
                            className='input-label'
                        >
                            {t('otherSubject', { ns: 'contact' })}
                        </Typography>

                        <OutlinedInput
                            className="input-field"
                            sx={{
                                color: '#262626 !important',
                                height: '42px',
                                p: 1,
                                width: '100%'
                            }}
                            value={otherSubject}
                            onChange={(event) => { setOtherSubject(event.target.value) }}
                            variant="filled"
                            type='email'
                        />
                    </Stack>

                    <Stack
                        sx={{
                            flexGrow: 1
                        }}
                    >
                        <Typography
                            className='input-label'
                        >
                            {t('otherContent', { ns: 'contact' })}
                        </Typography>

                        <TextField
                            className="input-field"
                            multiline
                            rows={8}
                            value={otherContent}
                            onChange={(event) => { setOtherContent(event.target.value) }}
                            variant="outlined"
                        />
                    </Stack>

                    <Stack
                        sx={{
                            flexGrow: 1
                        }}
                    >
                        <Button
                            size={'large'}
                            sx={{
                                my: 3,
                                borderRadius: 0,
                                backgroundColor: retailer.button_background_color,
                                color: isLight(hexToRgb(retailer.button_background_color)) ? 'black' : 'white',
                            }}
                            variant="contained"
                            onClick={sendMessage}
                        >
                            {t('submit', { ns: 'contact' }) }
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Container>
    )
}

export default Contact