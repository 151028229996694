import PropTypes, { arrayOf, string } from 'prop-types';

import DOMPurify from 'dompurify';
import { Box } from '@mui/system';

import styles from './index.module.scss';

function Wysiwyg({ component = 'div', allowedTags = [], children = '' }) {
  const config = {
    ALLOWED_TAGS: ['a', 'br', 'p', 'b', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'strong', 'span', 'div', ...allowedTags],
  };

  return (
    <Box
      className={'wysiwyg'}
      component={component}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children, config) }}
    />
  )
}

Wysiwyg.propTypes = {
  component: string,
  allowedTags: arrayOf(string),
  children: string,
}

export default Wysiwyg;