import React from 'react';
import { render } from 'react-dom';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import i18n from './i18n/config';

import App from './App';

import rootReducer from './reducers';

import reportWebVitals from './reportWebVitals';
import api from './api';
import { getTranslation } from './utils/translate';

let categoriesList = [];
let cookingMethodsList = [];

// TODO clean this up
const persistedState = localStorage.getItem('reduxState') 
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {
    filters: {
      prepTime: 0,
      cookTime: 0,
      chillTime: 0,
      freezingTime: 0,
      totalTime: 0,
      difficulty: 0,
      recipeYield: 0,
      categoryCheckedState: new Array(categoriesList.length).fill(null),
      cookingMethodCheckedState: new Array(cookingMethodsList.length).fill(null),
      ingredients: [],
    },
    search: {
      query: ''
    },
    language: {
      name: 'English',
      code: 'en-US',
    }
  };

const defaultRetailerSettings = {
  "id": "b4405e7d-44f9-42b7-b13b-75e8743aa064",
  "status": "published",
  "brand_color": "#BC0808",
  "brand_color_2": "#AAAAAA",
  "qr": null,
  "body_font": "Oswald",
  "header_font": "Oswald",
  "button_font": "Oswald",
  "icon_color": "#FFFFFF",
  "site_background_color": "#fff",
  "button_background_color": "#C31E39",
  "subdomain": "staging.cdnbeef.ca",
  "placeholder_image": "2d49d1d5-88a0-4df6-be8c-4a5f56bb5b3c",
  "favicon": "7972eaab-3862-4c25-aede-7cb614d640a6",
  "website_url": "http://staging.cdnbeef.ca",
  "allow_scanning": false,
  "store_url": "https://canadabeef.ca",
  "users": ["a6d5ff26-2524-4f86-ba44-c8e3abf8b544"], 
  "scripts": [],
  "associated_videos": [], "barcode_brand_alias": [],
  "translations": [{ "id": 3, "retailer_id": "b4405e7d-44f9-42b7-b13b-75e8743aa064", "languages_code": "fr-FR", "retailer_name": "Bœuf canadien", "title": "BSuf canadien", "description": "Nous mettons le meilleur du Canada dans notre bSuf", "header": null, "subheading": null, "recipe_heading": "Recipes", "recipe_description": null, "beef_cuts_heading": "Beef Cuts", "beef_cuts_description": null, "cooking_methods_heading": "How to Cook", "cooking_methods_description": null, "recipe_image": null, "beef_cuts_image": null, "cooking_methods_image": null }, { "id": 5, "retailer_id": "b4405e7d-44f9-42b7-b13b-75e8743aa064", "languages_code": "en-US", "retailer_name": "Canada Beef", "title": null, "description": null, "header": null, "subheading": null, "recipe_heading": "Recipes", "recipe_description": null, "beef_cuts_heading": "Beef Cuts", "beef_cuts_description": null, "cooking_methods_heading": "How to Cook", "cooking_methods_description": null, "recipe_image": null, "beef_cuts_image": null, "cooking_methods_image": null }, { "id": 6, "retailer_id": "b4405e7d-44f9-42b7-b13b-75e8743aa064", "languages_code": null, "retailer_name": "Kanadisches Rindfleisch", "title": null, "description": null, "header": null, "subheading": null, "recipe_heading": "Recipes", "recipe_description": null, "beef_cuts_heading": "Beef Cuts", "beef_cuts_description": null, "cooking_methods_heading": "How to Cook", "cooking_methods_description": null, "recipe_image": null, "beef_cuts_image": null, "cooking_methods_image": null }, { "id": 9, "retailer_id": "b4405e7d-44f9-42b7-b13b-75e8743aa064", "languages_code": "es-ES", "retailer_name": "Beef", "title": null, "description": null, "header": null, "subheading": null, "recipe_heading": "Recipes", "recipe_description": null, "beef_cuts_heading": "Beef Cuts", "beef_cuts_description": null, "cooking_methods_heading": "How to Cook", "cooking_methods_description": null, "recipe_image": null, "beef_cuts_image": null, "cooking_methods_image": null }],
  "beef_products": [],
  "beef_product_overrides": [],
  "beef_product_brandings": [],
  "cooking_methods": [],
  "menus": [],
  "regions": [],
  "redirect_sites": [],
  "logo": { "id": "d896a9bc-2d1a-478d-8cbf-352aba52156e", "storage": "local", "filename_disk": "d896a9bc-2d1a-478d-8cbf-352aba52156e.png", "filename_download": "beeflogoalpha.png", "title": "Beeflogoalpha", "type": "image/png", "folder": null, "uploaded_by": "a6d5ff26-2524-4f86-ba44-c8e3abf8b544", "uploaded_on": "2022-06-06T22:28:12.000Z", "modified_by": null, "modified_on": "2022-06-06T22:28:13.000Z", "charset": null, "filesize": 83163, "width": 425, "height": 325, "duration": null, "embed": null, "description": null, "location": null, "tags": null, "metadata": { "ihdr": { "Filter": "Adaptive", "BitDepth": 8, "Software": "Adobe ImageReady", "ColorType": "RGB with Alpha", "Interlace": "Noninterlaced", "ImageWidth": 425, "Compression": "Deflate/Inflate", "ImageHeight": 325 }}}
};

i18n.changeLanguage(persistedState.language.code.split('-')[0]);

const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

store.subscribe(()=>{
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})

const initialiseDocument = (retailer) => {
  const translatedRetailer = getTranslation(retailer);

  // add custom scripts
  retailer?.scripts?.forEach(script => {
    const template = document.createRange().createContextualFragment(script.name);

    document.head.appendChild(template);
  });

  document?.body?.style?.setProperty('--background-color', retailer.site_background_color);
  document?.body?.style?.setProperty('--primary-color', retailer.brand_color);
  document?.body?.style?.setProperty('--primary-font', retailer.header_font);

  document.title = translatedRetailer.title || '';

  // TODO copy meta tag content from canada beef site
  document.querySelectorAll('[itemprop="name"]')[0].content = translatedRetailer.retailer_name || '';
  document.querySelectorAll('[itemprop="description"]')[0].content = translatedRetailer.description || '';

  // if no favicon is provided use brand logo else use default favicon
  const favicon = retailer?.favicon?.id ?
    `${process.env.REACT_APP_API_URL}/assets/${retailer.favicon.id}` :
    `${process.env.REACT_APP_API_URL}/assets/${retailer?.logo?.id}`;

  // Facebook OG meta tags
  document.querySelectorAll('[property="og:title"]')[0].content = translatedRetailer.title || '';
  document.querySelectorAll('[property="og:description"]')[0].content = translatedRetailer.description || '';
  document.querySelectorAll('[property="og:url"]')[0].content = retailer.subdomain;
  document.querySelectorAll('[property="og:image"]')[0].content = `${favicon}?key=system-medium-contain`;

  // Twitter meta tags
  document.querySelectorAll('[name="twitter:title"]')[0].content = retailer.subdomain;
  document.querySelectorAll('[name="twitter:description"]')[0].content = translatedRetailer.description || '';
  document.querySelectorAll('[name="twitter:url"]')[0].content = `${favicon}?key=system-medium-contain`;

  // app icons
  document.querySelectorAll('[sizes="180x180"]')[0].href = `${favicon}?format=png&fit=contain&width=180&height=180`;
  document.querySelectorAll('[sizes="512x512"]')[0].href = `${favicon}?format=png&fit=contain&width=512&height=512`;
  document.querySelectorAll('[sizes="192x192"]')[0].href = `${favicon}?format=png&fit=contain&width=192&height=192`;
  document.querySelectorAll('[sizes="32x32"]')[0].href = `${favicon}?format=png&fit=contain&width=32&height=32`;
  document.querySelectorAll('[sizes="16x16"]')[0].href = `${favicon}?format=png&fit=contain&width=16&height=16`;

  // create & append manifest file
  const manifest = {
    name: translatedRetailer.retailer_name || '',
    short_name: translatedRetailer.retailer_name || '',
    icons: [
      { src: `${favicon}?format=png&fit=contain&width=192&height=192`, sizes: "192x192", type: "image/png" },
      { src: `${favicon}?format=png&fit=contain&width=512&height=512`, sizes: "512x512", type: "image/png" }
    ],
    start_url: retailer.subdomain,
    display: "standalone",
    theme_color: retailer.brand_color,
    background_color: retailer.site_background_color
  };

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#manifest').setAttribute('href', manifestURL);

  document.querySelectorAll('[name="description"]')[0].content = translatedRetailer.description || '';
}

const handleRedirect = (redirectSites, currentLocation) => {
  const allowRedirect = !window.location.search.includes('redirect=0');

  if (!allowRedirect) return;

  let urlToRedirectTo;

  // identify url to redirect to
  urlToRedirectTo = redirectSites.find(
    redirectSite => redirectSite
      .provinces
      ?.includes(currentLocation.region_code)
  )?.url || '';

  // if url exists and is not current site then redirect
  if (urlToRedirectTo && !urlToRedirectTo.includes(window.location.hostname)) {
    window.location.href = urlToRedirectTo + '?redirect=0';
  }
}

const fetchRetailers = () => {
  const subdomain = process.env.NODE_ENV === 'production' ?
    window.location.hostname :
    process.env.REACT_APP_BASE_URL
  ;

  // fetch generic retailer
  const genericGatewayRequest = api.getGenericRetailer();
  const retailersRequest = api.getListRetailer({
    deep: {
      regions: {
        _filter: {
          status: {
            _eq: 'published'
          }
        }
      },
      redirect_sites: {
        _filter: {
          status: {
            _eq: 'published'
          }
        }
      }
    },
    filter: {
      '_and': ([
        {
          domains: {
            domain: {
              '_eq': subdomain,
            }
          }
        }
      ])
    },
    fields: [
      '*',
      'domains.domain',
      'domains.id',
      'favicon.*',
      'logo.*',
      'redirect_sites.sort',
      'redirect_sites.status',
      'redirect_sites.url',
      'redirect_sites.provinces',
      'translations.*',
    ].join(',')
  });

  return Promise.all([genericGatewayRequest, retailersRequest])
}

fetchRetailers()
  .then(async ([genericRetailer, [currentRetailer]]) => {
    
    initialiseDocument(currentRetailer || genericRetailer || defaultRetailerSettings);

    const subdomain = process.env.NODE_ENV === 'production' ?
      window.location.hostname :
      process.env.REACT_APP_BASE_URL
    ;

    if (typeof window !== "undefined") {
      window.sessionStorage.setItem(subdomain, JSON.stringify(currentRetailer || genericRetailer || defaultRetailerSettings));
    }

    // geolocate user
    let currentLocation = {
      region: '',
      region_code: ''
    };

    try {
      currentLocation = await api.geolocateUsingIp();

      // check if there are redirect settings
      const redirectSites = currentRetailer?.redirect_sites.sort((a, b) => {
        return a.sort - b.sort;
      });

      if (redirectSites?.length) {
        handleRedirect(redirectSites, currentLocation)
      }
    } catch(geolocateError) {
      // do nothing if it fails to geolocate
    }
  })
  .catch(error => {
    initialiseDocument(defaultRetailerSettings);

    const subdomain = process.env.NODE_ENV === 'production' ?
      window.location.hostname :
      process.env.REACT_APP_BASE_URL
    ;

    if (typeof window !== "undefined") {
      window.sessionStorage.setItem(subdomain, JSON.stringify(defaultRetailerSettings));
    }
  })
  .then(() => {
    render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root'),
    );

    reportWebVitals();
  });
