import { bool, node } from "prop-types";

import Header from "../../components/Header";
import BackButton from "../../components/BackButton";
import PageLogo from "../../components/PageLogo";
import Footer from "../../components/Footer";
import { Box } from "@mui/material";

const PageLayout = ({children, showLogo=true, }) => {
    return (
        <>
            <Box
                sx={{
                    minHeight: 'calc(100vh - 320px)',
                }}
            >    
                <Header />
                <BackButton />
                {showLogo && <PageLogo />}
                {children}
            </Box>
            <Footer />
        </>
    )
}

PageLayout.propTypes = {
    children: node.isRequired,
    showLogo: bool
}

export default PageLayout;